import React, { useState } from 'react';
import { Button, Form, Input, Layout, Row, Col } from 'antd';
import LockOutlined from '@ant-design/icons';
import UserOutlined from '@ant-design/icons';

const { Content } = Layout;
const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Add your login logic here
    console.log('Email:', email);
    console.log('Password:', password);
  };

  console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL);
  return (
    <Layout>
      <Content className="h-screen flex items-center">
        <Row justify="center">
          <Col xs={24} sm={12} md={10} lg={8} xl={6}>
            <div className="p-8 bg-white shadow-lg rounded-lg">
              <Form onFinish={handleSubmit}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email address',
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: 'Please enter your password' },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Sign In
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Login;
