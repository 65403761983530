import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Login from './containers/Login';
import Page404 from './containers/Page404';

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
